import React, { useState, useEffect, useRef } from "react";
import getData from "../getdata/GetData";
import { useParams } from "react-router-dom";
import CheckList from "./CheckList";
import TicketControlPanel from "./TicketControlPanel";
import TicketMain from "./TicketMain";
import TicketTabs from "./TicketTabs";
import PlugAccess from "components/PlugAccess";
import NotPaid from "components/NotPaid";
import NotFound from "components/NotFound";
import Unauthorized from "components/Unauthorized";

const Ticket = () => {
  let { id } = useParams();
  const [idTicket, setIdTicket] = useState(id);
  const [changeField, setChangeField] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [ticketStatusCode, setTicketStatusCode] = useState("");
  const changeFieldRef = useRef(changeField);
  const idRef = useRef(id);

  useEffect(() => {
    if (idRef !== id && id === "new") {
      setIdTicket(id);
    }
  }, [id]);

  useEffect(() => {
    changeFieldRef.current = changeField;
  }, [changeField]);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  //добавить проверку на существования id задачи

  useEffect(() => {
    if (idTicket === false) {
      setIdTicket(id);
    }

    if (idTicket === "new") {
      getTicketNew();
    }
  }, [idTicket]);

  async function getTicketNew() {
    if (idTicket === "new") {
      const request_parameters = { module: "sd", block: "ticket_main" };
      const request_attribute = { id_ticket: id };
      const result = await getData(request_parameters, request_attribute);

      const idNewTicket =
        result && result.request_data && result.request_data.id
          ? result.request_data.id
          : [];
      if (idTicket === "new" && idNewTicket > 0) {
        window.location.replace("/task/" + idNewTicket + "/");
      }
    }
  }

  function handlePaste(pasteEvent) {
    const item = pasteEvent.clipboardData.items[0];
    if (
      item.type.indexOf("image") === 0 &&
      pasteEvent.target.id === "textarea-comment"
    ) {
      var blob = item.getAsFile();

      setSelectedFile((prevSelectedFile) => [...prevSelectedFile, blob]);
    }
  }

  function addOnChange(changeFile) {
    if (changeFile.target.files && changeFile.target.files.length > 0) {
      let arrFilesAll = Array.from(changeFile.target.files);
      arrFilesAll.forEach((element) => {
        setSelectedFile((prevSelectedFile) => [...prevSelectedFile, element]);
      });
    }
  }

  const deleteFileFromState = (index) => {
    const newArray = [...selectedFile]; // Создаем копию массива
    newArray.splice(index, 1); // Удаляем элемент по индексу
    setSelectedFile(newArray); // Обновляем состояние с новым массивом
  };

  if (idTicket > 0) {
    return (
      <div className="container">
        {ticketStatusCode === "404" ? (
          <>
            <NotFound />
          </>
        ) : ticketStatusCode === "403" ? (
          <PlugAccess />
        ) : ticketStatusCode === "402" ? (
          <NotPaid />
        ) : ticketStatusCode === "401" ? (
          <Unauthorized />
        ) : (
          <div className="landing-ticket">
            <div className="element-loading"></div>
            <div className="control-panel-ticket-body">
              <div className="ticket-block-body">
                <div className="ticket-block-body-tabs-content">
                  <div className="content_ticket_block_body border_background">
                    <TicketMain
                      idTicket={idTicket}
                      changeField={changeField}
                      selectedFile={selectedFile}
                      setChangeField={setChangeField}
                      setSelectedFile={setSelectedFile}
                      deleteFileFromState={deleteFileFromState}
                      addOnChange={addOnChange}
                      typeItem={"ticket"}
                      setTicketStatusCode={setTicketStatusCode}
                    />
                    <CheckList typeItem={"ticket"} />
                  </div>
                  <TicketTabs
                    idTicket={idTicket}
                    changeField={changeField}
                    selectedFile={selectedFile}
                    setChangeField={setChangeField}
                    setSelectedFile={setSelectedFile}
                    deleteFileFromState={deleteFileFromState}
                    addOnChange={addOnChange}
                  />
                </div>
              </div>

              <TicketControlPanel
                idTicket={idTicket}
                changeField={changeField}
                setChangeField={setChangeField}
                changeFieldRef={changeFieldRef}
                typeItem={"ticket"}
              />
            </div>
          </div>
        )}
      </div>
    );
  } else if (idTicket === "new") {
    return <div>Задача создается</div>;
  } else {
    return <NotFound />;
  }
};

export default Ticket;
