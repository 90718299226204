import { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import PlugAccess from "components/PlugAccess";
import { BiCoinStack } from "react-icons/bi";
import { CiGrid41, CiCreditCard1 } from "react-icons/ci";
import { IoCheckboxSharp } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { GoPlus, GoDash } from "react-icons/go";
import $ from "jquery";
import NavDropdown from "react-bootstrap/NavDropdown";
import { arrHistoryPayment } from "../const/ConstPayment";

function Payment() {
  const [itemsPayment, setItemsPayment] = useState([]);
  const [responseCode, setResponseCode] = useState(null);
  const [costConfiguration, setCostConfiguration] = useState(null);
  const [forecastDate, setForecastDate] = useState([]);
  const [itemsPaymentHistory, setItemsPaymentHistory] = useState(null);
  const [selectPeriod, setSelectPeriod] = useState("Произвольная сумма");
  const [selectPeriodNum, setSelectPeriodNum] = useState("0");
  const [paymentAmount, setPaymentAmount] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("Банковская карта");
  const [paymentType, setPaymentType] = useState("1");
  const [savePayment, setSavePayment] = useState(false);
  const [savePaymentText, setSavePaymentText] = useState("");

  const getPayment = async (action) => {
    const request_parameters = {
      module: "settings",
      block: "tariff",
    };
    const request_attribute = {
      action: action,
    };
    let request_data = [];
    if (action === "change_config") {
      let arrActiveModules = [];

      itemsPayment.configuration.module.forEach((itemspaymentvalue, index) => {
        if (itemspaymentvalue && itemspaymentvalue.availability === "y") {
          arrActiveModules[itemspaymentvalue.id] = "y";
        }
      });

      request_data = {
        value: {
          quantity_users: itemsPayment.configuration.quantity_users.value,
          modules: arrActiveModules,
        },
      };
    }
    if (action === "new_payment") {
      request_data = {
        value: {
          paymenttype: paymentType,
          paymentamount: paymentAmount,
          selectperiodnum: selectPeriodNum,
        },
      };
    }

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrItemsPayment =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setItemsPayment(arrItemsPayment);
    const settingResponseCode =
      result && result.request_attribute && result.request_attribute.status_code
        ? result.request_attribute.status_code
        : null;

    setResponseCode(settingResponseCode);
  };

  useEffect(() => {
    if (savePayment) {
      setSavePaymentText("Конфигурация сохранена");
      window.location.replace("/payment/");
    }
  }, [savePayment]);

  function changeItemsPayment(action, value) {
    if (action === "module" && value) {
      const updatedItems = itemsPayment.configuration.module.map((item) => {
        if (item.id === value && item.availability === "y") {
          return { ...item, availability: "n" };
        }
        if (item.id === value && item.availability === "n") {
          return { ...item, availability: "y" };
        }
        return item;
      });
      setItemsPayment({
        ...itemsPayment,
        configuration: { ...itemsPayment.configuration, module: updatedItems },
      });
    }

    if (action === "quantity_users" && value && value > 0) {
      setItemsPayment({
        ...itemsPayment,
        configuration: {
          ...itemsPayment.configuration,
          quantity_users: {
            ...itemsPayment.configuration.quantity_users,
            value: value,
          },
        },
      });
    }
  }

  function getCost() {
    if (
      itemsPayment &&
      itemsPayment.configuration &&
      itemsPayment.configuration.module
    ) {
      let costConfiguration = 0;
      itemsPayment.configuration.module.forEach((itemspaymentvalue, index) => {
        if (itemspaymentvalue && itemspaymentvalue.availability === "y") {
          if ( itemsPayment.configuration.quantity_users.value > 4 && itemsPayment.configuration.quantity_users.value <= 9 ) {
            costConfiguration += itemspaymentvalue.price2 * itemsPayment.configuration.quantity_users.value;
          } else if ( itemsPayment.configuration.quantity_users.value > 9 && itemsPayment.configuration.quantity_users.value <= 49 ) {
            costConfiguration += itemspaymentvalue.price3 * itemsPayment.configuration.quantity_users.value;
          } else if ( itemsPayment.configuration.quantity_users.value > 49 ) {
            costConfiguration += itemspaymentvalue.price4 * itemsPayment.configuration.quantity_users.value;
          } else {
            costConfiguration += itemspaymentvalue.price * itemsPayment.configuration.quantity_users.value;
          }
          
        }
      });
      setCostConfiguration(costConfiguration);

      if (selectPeriodNum == 0) {
        setPaymentAmount(costConfiguration);
      } else {
        setPaymentAmount(costConfiguration * selectPeriodNum);
      }
    }
  }

  useEffect(() => {
    getCost();
  }, [itemsPayment]);

  useEffect(() => {
    dateForecast();
  }, [costConfiguration]);

  useEffect(() => {
    getPayment();
  }, []);

  function dateForecast() {
    if (
      itemsPayment &&
      itemsPayment.balans &&
      itemsPayment.balans.value &&
      costConfiguration
    ) {
      const forecast = Math.round(
        itemsPayment.balans.value / (costConfiguration / 31)
      );

      const today = new Date();
      const endDate = new Date(
        today.getTime() + forecast * 24 * 60 * 60 * 1000
      );
      const months = Math.floor((endDate - today) / (30 * 24 * 60 * 60 * 1000));
      const days = Math.floor((endDate - today) / (24 * 60 * 60 * 1000)) % 30;
      const outputMoths =
        months !== 0
          ? `${months} месяц${
              months === 1
                ? ""
                : months === 5 ||
                  months === 6 ||
                  months === 7 ||
                  months === 8 ||
                  months === 9
                ? "ев"
                : "а"
            } `
          : "";
      const outputDays =
        days !== 0
          ? `${days} ${
              days === 1 || days === 21
                ? "день"
                : days === 2 ||
                  days === 3 ||
                  days === 4 ||
                  days === 22 ||
                  days === 23 ||
                  days === 24
                ? "дня"
                : "дней"
            }`
          : "";
      const outputEndDate = `. До ${endDate.toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`;

      const output =
        `Хватит примерно на ` +
        outputMoths +
        `${outputMoths === "" || outputDays === "" ? "" : "и "}` +
        outputDays +
        outputEndDate;

      setForecastDate(output);
    }
  }

  function paymentTypeReturn() {
    if (paymentType === "1") {
      return (
        <div>
          <input
            type="hidden"
            name="artn_order"
            defaultValue={itemsPayment.order_info.order_num}
          />
          <input
            type="hidden"
            name="artn_desc"
            defaultValue="Оплата услуг в Aritin.ru"
          />
          <input
            type="hidden"
            name="artn_mail"
            defaultValue={itemsPayment.order_info.mail}
          />
          <input
            type="hidden"
            name="artn_phone"
            defaultValue={itemsPayment.order_info.phone}
          />
          <input
            type="hidden"
            name="artn_token"
            defaultValue={itemsPayment.order_info.token}
          />
          <input
            type="hidden"
            name="artn_key"
            defaultValue="artsdfdsfn_kosdsfdstiajjsllff1524dfdf"
          />

          <input
            className="payform-tinkoff-row payform-tinkoff-btn a-btn-block btn-balance a-solid "
            type="submit"
            value="Оплатить"
            onClick={() => getPayment("new_payment")}
          />
        </div>
      );
    } else if (paymentType === "2") {
      return (
        <div className="a-btn-block btn-balance a-solid">Выставить счет</div>
      );
    }
  }

  if (responseCode && responseCode === "403") {
    return <PlugAccess />;
  } else if (responseCode && responseCode === "200") {
    return (
      <>
        <div className="paument_container">
          <div className="container">
            <div className="a-text-1 a-section-title">Тарифы и оплата</div>
            <div className="row">
                {itemsPayment && itemsPayment.demo_days && itemsPayment.demo_days > 0 ? 
                
                  (
                    <div className="col-12 pb-4"><div className="demo_info">
                    <div className="demo_info_title">Ознакомительный период</div>
                    <div>В ознакомительный период списание денежных средств не происходит.</div>
                    <div>Пожалуйста, пополните баланс до окончания периода, чтобы избежать блокировки аккаунта и продолжить пользоваться всеми функциями</div>
                    
                    <div className="demo_info_days">До окончания ознакомительного периода <b>осталось {itemsPayment.demo_days} 
                    {itemsPayment.demo_days === 1 ? ' день' : 
    itemsPayment.demo_days >= 2 && itemsPayment.demo_days <= 4 ? ' дня' : ' дней'}                 
                    !</b></div>
                    
                  </div></div>)

                



                : false}
              
              <div className="col-12 pb-4">
                <div className="border_bg p-2 d_flex_g20">
                  <div className="cash_balance_icon">
                    <BiCoinStack />
                  </div>

                  <div>
                    <div className="title_h2 p-1">
                      Баланс:{" "}
                      {itemsPayment &&
                      itemsPayment.balans &&
                      itemsPayment.balans.value
                        ? itemsPayment.balans.value
                        : "Нет данных"}{" "}
                      р.
                    </div>

                    {itemsPayment.balans.value &&
                    itemsPayment.balans.value > 0 ? (
                      forecastDate ? (
                        <div className="p-1 a-text--c-six">{forecastDate}</div>
                      ) : (
                        false
                      )
                    ) : (
                      <div className="p-1 a-text--c-six colorpayment1">
                        Пополните, пожалуйста, баланс
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-6 pb-4">
                <div className="border_bg p-2 left-block-module a-relative">
                  <div className="container border_bg_b_c_three p-2">
                    <div className="row">
                      <div className="col-4 a-text-2">Модули</div>
                      <div className="col-8 a-text-4 a-flex-center">
                        Подключенных пользователей:{" "}
                        {itemsPayment &&
                        itemsPayment.configuration &&
                        itemsPayment.configuration.quantity_users &&
                        itemsPayment.configuration.quantity_users.value
                          ? itemsPayment.configuration.quantity_users.value
                          : "Нет данных"}
                      </div>
                    </div>
                  </div>

                  {itemsPayment &&
                  itemsPayment.configuration &&
                  itemsPayment.configuration.module
                    ? itemsPayment.configuration.module.map(
                        (itemspaymentvalue, index) =>
                          itemspaymentvalue ? (
                            <div key={index} className="a-border-bg-b">
                              <div className="a-flex a-gap-10 p-3 a-relative itemspaymentvalue">
                                {itemspaymentvalue.id !== 101 &&
                                itemspaymentvalue.id !== 102 ? (
                                  <div
                                    onClick={() =>
                                      changeItemsPayment(
                                        "module",
                                        itemspaymentvalue.id
                                      )
                                    }
                                    className={`p-1 a-text-4 a-pointer check_box ${
                                      itemspaymentvalue.availability === "y"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    {itemspaymentvalue.availability === "y" ? (
                                      <IoCheckboxSharp />
                                    ) : (
                                      <MdCheckBoxOutlineBlank />
                                    )}
                                  </div>
                                ) : (
                                  <div className="p-1 a-text-4 check_box always-active">
                                    <IoCheckboxSharp />
                                  </div>
                                )}

                                <div className="p-1 a-text-4">
                                  <CiGrid41 />
                                </div>
                                <div className="p-1 a-text-4">
                                  {itemspaymentvalue.name}
                                </div>
                                <div className="p-1 a-text-4 price-modules">
                                  <div className={ itemsPayment.configuration.quantity_users.value > 4 && itemspaymentvalue.price > 0 ? 'old_price' : false}>
                                    {itemspaymentvalue.price * itemsPayment.configuration.quantity_users.value}{" "}руб./мес.
                                  </div>
                                  { itemsPayment.configuration.quantity_users.value > 4 && itemsPayment.configuration.quantity_users.value <= 9 && itemspaymentvalue.price > 0 ? (
                                      <div className="new_price">
                                    {itemspaymentvalue.price2 * itemsPayment.configuration.quantity_users.value}{" "}руб./мес.
                                      </div>
                                  ) : itemsPayment.configuration.quantity_users.value > 9 && itemsPayment.configuration.quantity_users.value <= 49  && itemspaymentvalue.price > 0 ? (
                                    <div className="new_price">
                                    {itemspaymentvalue.price3 * itemsPayment.configuration.quantity_users.value}{" "}руб./мес.
                                    </div>
                                ) : itemsPayment.configuration.quantity_users.value > 49 && itemspaymentvalue.price > 0 ? (
                                  <div className="new_price">
                                    {itemspaymentvalue.price4 * itemsPayment.configuration.quantity_users.value}{" "}руб./мес.
                                  </div>
                              ) : false }
                                </div>
                              </div>
                              <div>
                                {/*
                                {itemspaymentvalue.price} руб./ мес. за
                                пользователя*/}
                              </div>
                            </div>
                          ) : (
                            false
                          )
                      )
                    : false}
                  <div className="change-quantity_users p-2">
                    <div className="a-flex a-gap-10 a-ai-center">
                      <div className="a-text--c-six">
                        Изменить количество пользователей
                      </div>

                      <div
                        className="minus a-pointer"
                        onClick={() => {
                          let quantity_users = $("#quantity_users").val();
                          quantity_users--;
                          changeItemsPayment("quantity_users", quantity_users);
                        }}
                      >
                        <GoDash />
                      </div>

                      <input
                        className="input-quantity_users"
                        id="quantity_users"
                        placeholder="количество пользователей"
                        onChange={(e) =>
                          changeItemsPayment("quantity_users", e.target.value)
                        }
                        value={
                          itemsPayment &&
                          itemsPayment.configuration &&
                          itemsPayment.configuration.quantity_users &&
                          itemsPayment.configuration.quantity_users.value
                            ? itemsPayment.configuration.quantity_users.value
                            : 0
                        }
                      ></input>

                      <div
                        className="plus a-pointer"
                        onClick={() => {
                          let quantity_users = $("#quantity_users").val();
                          quantity_users++;
                          changeItemsPayment("quantity_users", quantity_users);
                        }}
                      >
                        <GoPlus />
                      </div>
                    </div>
                    <div className="a-flex pt-3 a-relative">
                      <div
                        className={`a-btn-block ${
                          savePaymentText &&
                          savePaymentText === "Конфигурация сохранена"
                            ? "access"
                            : ""
                        }`}
                        onClick={() => {
                          getPayment("change_config");
                          setSavePayment(true);
                        }}
                      >
                        {savePaymentText &&
                        savePaymentText === "Конфигурация сохранена"
                          ? savePaymentText
                          : "Сохранить конфигурацию"}
                      </div>
                      <div className="total-price a-text-3">
                        Итого {costConfiguration ? costConfiguration : false}{" "}
                        руб./мес
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 pb-4">
                <div className="border_bg p-2 right-block-balance a-relative">
                  <div className="container border_bg_b_c_three p-2">
                    <div className="row">
                      <div className="col-12 a-text-2">Пополнение баланса</div>
                    </div>
                  </div>
                  <form
                    target="_blank"
                    className="payform-tinkoff"
                    id="payform-tinkoff"
                    action="https://aritin.ru/payment/"
                    method="post"
                  >
                    <div className="p-2">
                      <div className="p-1 a-text-4">Период оплаты</div>
                      <NavDropdown
                        className="p-1 selector-block-balance"
                        title={selectPeriod}
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={() => {
                            setSelectPeriod("Произвольная сумма");
                            setSelectPeriodNum("0");
                          }}
                        >
                          Произвольная сумма
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          onClick={() => {
                            setSelectPeriod("1 месяц");
                            setPaymentAmount(costConfiguration);
                            setSelectPeriodNum("1");
                          }}
                        >
                          1 месяц
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            setSelectPeriod("3 месяца");
                            setPaymentAmount(costConfiguration * 3);
                            setSelectPeriodNum("3");
                          }}
                        >
                          3 месяца
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            setSelectPeriod("6 месяцев");
                            setPaymentAmount(costConfiguration * 6);
                            setSelectPeriodNum("6");
                          }}
                        >
                          6 месяцев
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          onClick={() => {
                            setSelectPeriod("12 месяцев");
                            setPaymentAmount(costConfiguration * 12);
                            setSelectPeriodNum("12");
                          }}
                        >
                          12 месяцев
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                    <div className="p-2">
                      <div className="p-1 a-text-4">Сумма платежа</div>
                      <div className="">
                        <input
                          name="artn_amount"
                          className="selector-block-balance"
                          value={paymentAmount ? paymentAmount : ""}
                          onChange={(e) => setPaymentAmount(e.target.value)}
                        ></input>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="p-1 a-text-4">Способ оплаты</div>
                      <NavDropdown
                        className="p-1 selector-block-balance"
                        title={paymentMethod}
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={() => {
                            setPaymentMethod("Банковская карта");
                            setPaymentType("1");
                          }}
                        >
                          Банковская карта
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          onClick={() => {
                            setPaymentMethod("Выставление счета для юр/лиц.");
                            setPaymentType("2");
                          }}
                        >
                          Выставление счета для юр/лиц.
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                    <div>{paymentTypeReturn()}</div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="border_bg p-2">
                <div className="container border_bg_b_c_three p-2">
                  <div className="row">
                    <div className="col-12 a-text-2">История операций</div>
                  </div>
                </div>
                <div className="container">
                  <div className="row a-border-bg-b a-text--c-six p-2">
                    <div className="col-3">Дата</div>
                    <div className="col-2">Сумма</div>
                    <div className="col-3">Статус платежа</div>
                    <div className="col-4">Событие</div>
                  </div>
                </div>
                {itemsPayment && itemsPayment.payment_history
                  ? itemsPayment.payment_history.map(
                      (paymenthistoryvalue, index) =>
                        paymenthistoryvalue &&
                        (itemsPaymentHistory === "openHistoryItems" ||
                          index < 3) ? (
                          <div key={index} className="a-border-bg-b">
                            <div className="row p-3">
                              <div className="col-3">
                                {paymenthistoryvalue.date
                                  ? new Date(
                                      paymenthistoryvalue.date
                                    ).toLocaleDateString("ru-RU", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      second: "numeric",
                                      hour12: false,
                                    })
                                  : false}
                              </div>

                              {paymenthistoryvalue.status === "1" ? (
                                <div
                                  className={`col-2 colorpayment${paymenthistoryvalue.status}`}
                                >
                                  -{paymenthistoryvalue.price} руб.
                                </div>
                              ) : (
                                <div
                                  className={`col-2 colorpayment${paymenthistoryvalue.status}`}
                                >
                                  {paymenthistoryvalue.price} руб.
                                </div>
                              )}

                              <div
                                className={`col-3 colorpayment${paymenthistoryvalue.status}`}
                              >
                                {arrHistoryPayment[paymenthistoryvalue.status]}
                              </div>
                              <div className="col-4">
                                {paymenthistoryvalue.name}
                              </div>
                            </div>
                          </div>
                        ) : null
                    )
                  : false}
                <div className="p-2">
                  {itemsPayment &&
                  itemsPayment.payment_history &&
                  itemsPayment.payment_history.length > 3 ? (
                    itemsPaymentHistory === "openHistoryItems" ? (
                      <div
                        className="a-btn-block"
                        onClick={() => setItemsPaymentHistory(null)}
                      >
                        {itemsPayment &&
                        itemsPayment.configuration &&
                        itemsPayment.configuration.module
                          ? "Свернуть"
                          : false}{" "}
                      </div>
                    ) : (
                      <div
                        className="a-btn-block"
                        onClick={() =>
                          setItemsPaymentHistory("openHistoryItems")
                        }
                      >
                        {itemsPayment &&
                        itemsPayment.configuration &&
                        itemsPayment.configuration.module
                          ? "Посмотреть еще"
                          : false}{" "}
                      </div>
                    )
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Payment;
