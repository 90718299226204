import { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import { IoCheckboxSharp } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import FunctionChange from "../../../FunctionChange";
import PlugAccess from "components/PlugAccess";
import { arrTitleRoles } from "../const/DataRoles";

function Roles() {
  const [listRoles, setListRoles] = useState([]);
  const [listOpenRights, setlistOpenRights] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [responseCode, setResponseCode] = useState(null);

  useEffect(() => {
    getRoles();
    getRights("1");
  }, []);

  const getRoles = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "rights_roles",
    };
    const request_attribute = {
      type_data: "list_roles",
      action: action,
      id_item: id_item,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrListRoles =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setListRoles(arrListRoles);
    const settingResponseCode =
      result && result.request_attribute && result.request_attribute.status_code
        ? result.request_attribute.status_code
        : null;

    setResponseCode(settingResponseCode);
  };

  const getRights = async (id_item, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "rights_roles",
    };
    const request_attribute = {
      type_data: "role",
      id_item: id_item,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    let arrRights = {
      name: [],
      rights: { task: [], ticket: [], dopservice: [] },
    };
    if (
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.rights &&
      result.request_data.items.rights.rights
    ) {
      result.request_data.items.rights.rights.forEach((item) => {
        if (item.id <= 100) {
          arrRights.rights.ticket.push(item);
        } else if (item.id > 100 && item.id <= 200) {
          arrRights.rights.task.push(item);
        } else if (item.id > 200) {
          arrRights.rights.dopservice.push(item);
        }
      });
    }

    if (
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.rights &&
      result.request_data.items.rights.name
    ) {
      arrRights.name = result.request_data.items.rights.name;
    }

    setlistOpenRights(arrRights);

    setIsActive(id_item);

    if (edit_field === "name") {
      getRoles();
    }
  };

  function getValue(fieldType, value, id_item) {
    getRights(id_item, fieldType, value);
  }

  if (responseCode && responseCode === "403") {
    return <PlugAccess />;
  } else if (responseCode && responseCode === "200") {
    return (
      <>
        <div className="roles-block">
          <div className="container">
            <div className="roles_right_heading">
              <div className="a-text-1 a-section-title">Права и Роли</div>
              <div
                className="a-btn_block_add"
                onClick={() => getRoles("", "add")}
              >
                Добавить роль
              </div>
            </div>

            <div className="listroles_one_two">
              <div className="listroles_one border_background">
                <div className="roles_title title_h2">Роли</div>
                {listRoles
                  ? listRoles.map((listrolesvalue, index) =>
                      listrolesvalue ? (
                        <div
                          className={`roles_block cp ${
                            listrolesvalue.id.value === isActive
                              ? "active"
                              : false
                          }`}
                          key={index}
                          onClick={() => getRights(listrolesvalue.id.value)}
                        >
                          <div className="roles_name">
                            {listrolesvalue.name.value}
                          </div>

                          {listrolesvalue.id.value > 1 ? (
                            <div
                              className="block_delete_fitrash"
                              onClick={() => {
                                if (window.confirm("Удалить?")) {
                                  getRoles(listrolesvalue.id.value, "delete");
                                }
                              }}
                            >
                              <FiTrash />
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      ) : (
                        false
                      )
                    )
                  : false}
              </div>

              <div className="listroles_two border_background">
                <div className="right_title">
                  {listOpenRights && listOpenRights.name ? (
                    <FunctionChange
                      getValue={getValue}
                      propsValue={
                        listOpenRights &&
                        listOpenRights.name &&
                        listOpenRights.name.value
                          ? listOpenRights.name.value
                          : false
                      }
                      fieldType="name"
                      propsState={false}
                      propsIditem={isActive ? isActive : false}
                    />
                  ) : (
                    false
                  )}
                </div>
                <div className="listroles_rights_block">
                  {listOpenRights && listOpenRights.rights
                    ? Object.keys(listOpenRights.rights).map(
                        (listopenrightsmap, index) => {
                          const titleRoles = arrTitleRoles[listopenrightsmap];
                          return (
                            <div
                              key={index}
                              className="border_bg_b_c_three listroles_rights_area"
                            >
                              <div className="title_roles a-text-2">
                                {titleRoles.title}
                              </div>
                              {listOpenRights.rights[listopenrightsmap].map(
                                (rightsitemsmap, index2) => (
                                  <div
                                    className="listroles_rights_items"
                                    key={index2}
                                  >
                                    <div className="listroles_rights">
                                      {rightsitemsmap.value}
                                    </div>
                                    {isActive > 1 ? (
                                      <div
                                        className={`listroles_rights_icon ${
                                          rightsitemsmap.active === "y"
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          getRights(
                                            isActive,
                                            rightsitemsmap.field,
                                            rightsitemsmap.id
                                          )
                                        }
                                      >
                                        {rightsitemsmap.active === "y" ? (
                                          <IoCheckboxSharp />
                                        ) : (
                                          <MdCheckBoxOutlineBlank />
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className={`listroles_rights_icon ${
                                          rightsitemsmap.active === "y"
                                            ? "active_not_edited"
                                            : ""
                                        }`}
                                      >
                                        {rightsitemsmap.active === "y" ? (
                                          <IoCheckboxSharp />
                                        ) : (
                                          <MdCheckBoxOutlineBlank />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          );
                        }
                      )
                    : false}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Roles;
