import { useState, useEffect } from "react";
import getData from "./unit/servicdesk/getdata/GetData";
import md5 from "md5";
import $ from "jquery";
import logoblack from "../image/img/logo.png";
import madeInRF from "../image/img/made_in_rf.jpeg";
import Captcha from "./Captcha";

function Password() {
  const [passwordValue, setPasswordValue] = useState([]);
  const [passwordCaptcha, setPasswordCaptcha] = useState([]);
  const [captchaResult, setCaptchaResult] = useState(false);
  const [rebootCaptcha, setRebootCaptcha] = useState(false);

  const getUserPassword = async (value, value_1, value_2, value_3) => {
    const request_parameters = {
      module: "auth",
      block: "forgot_password_3",
    };
    const request_attribute = {
      action: "action_3",
    };

    const request_data = {
      value: value,
      value_1: md5(value_1),
      value_2: md5(value_2),
      value_3: value_3,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrUserPassword =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setPasswordValue(arrUserPassword);
  };

  const symbols_1 = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  const symbols_2 = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const symbols_3 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

  return (
    <>
      <div className="confirm_password_all_content">
        <div className="confirm_password">
          <div className="confirm_password_left_right">
            <div className="confirm_password_left">
              <div className="confirm_password_logoblack">
                <div className="confirm_password_logoblack_text_slogan">
                  <div className="confirm_password_logoblack_logo">
                    <img src={logoblack} alt="logo" />
                  </div>
                  <div>
                    <div className="confirm_password_logoblack_made_in_rf">
                      <img src={madeInRF} alt="made in RF" />
                    </div>
                  </div>
                </div>
                <div className="confirm_password_text_slogan">
                  <div className="confirm_password_description_text">
                    Программное обеспечение «Аритин» полностью разработано в
                    России, исключительно российскими специалистами. Серверы, на
                    которых работает система, размещаются на территории
                    Российской Федерации.
                  </div>
                  <div className="confirm_password_aritin_made_in_rf title_h2">
                    Aritin © 2023-2024 Сделано в РФ
                  </div>
                </div>
              </div>
            </div>
            <div className="confirm_password_right border_background">
              <div className="confirm_password_title_area">
                <div className="confirm_password_title_block border_bottom_background title_h2">
                  Восстановление пароля
                </div>
              </div>
              {passwordValue &&
              passwordValue.status &&
              passwordValue.status === "y" ? (
                <div className="working">Пароль успешно изменен. Работаем!</div>
              ) : (
                <div className="confirm_password_input_block">
                  <div className="confirm_password_input">
                    <input
                      className="input_password_no_auth"
                      id="password_no_auth"
                      type="text"
                      placeholder="Код из почты"
                    ></input>
                  </div>

                  <div className="confirm_password_input">
                    <input
                      className="input_password_no_auth"
                      id="password_no_auth_new"
                      type="password"
                      placeholder="Новый пароль"
                    ></input>
                  </div>
                  <div className="confirm_password_text">
                    Пароль должен содержать, более 8 символов - цифры, заглавные
                    и строчные буквы латинского языка
                  </div>
                  <div className="confirm_password_input">
                    <input
                      className="input_password_no_auth"
                      id="password_no_auth_new_new"
                      type="password"
                      placeholder="Подтвердите новый пароль"
                    ></input>
                  </div>
                  <div className="confirm_password_input">
                    <input
                      className="input_password_no_auth"
                      id="email_no_auth"
                      type="email"
                      placeholder="Ввведите свой email"
                    ></input>
                  </div>
                  <div className="block-captcha">
                    <Captcha
                      propssetResultKeyCaptcha={setPasswordCaptcha}
                      propsresultKeyCaptcha={passwordCaptcha}
                      propssetCaptchaResult={setCaptchaResult}
                      rebootCaptcha={rebootCaptcha}
                      setRebootCaptcha={setRebootCaptcha}
                    />
                  </div>
                  {captchaResult ? <div>{captchaResult}</div> : false}
                </div>
              )}

              {passwordValue &&
              passwordValue.status &&
              passwordValue.status === "y" ? (
                <div className="btn_enter">
                  <a
                    className="change_password_no_auth"
                    href="https://auth.aritin.ru/"
                  >
                    Войти
                  </a>
                </div>
              ) : (
                <div className="confirm_password_send_button">
                  <div
                    onClick={() => {
                      var password_mail = $("#password_no_auth ").val();
                      var password_new = $("#password_no_auth_new").val();
                      var password_new_new = $(
                        "#password_no_auth_new_new"
                      ).val();
                      var user_mail = $("#email_no_auth").val();
                      if (passwordCaptcha && passwordCaptcha === true) {
                        if (
                          user_mail &&
                          password_new_new &&
                          password_new &&
                          password_mail
                        ) {
                          if (password_new === password_new_new) {
                            if (password_new.length > 7) {
                              if (
                                symbols_1.some((symbol) =>
                                  password_new_new.includes(symbol)
                                ) &&
                                symbols_2.some((symbol) =>
                                  password_new_new.includes(symbol)
                                ) &&
                                symbols_3.some((symbol) =>
                                  password_new_new.includes(symbol)
                                )
                              ) {
                                getUserPassword(
                                  password_mail,
                                  password_new,
                                  password_new_new,
                                  user_mail
                                );
                                setCaptchaResult(false);
                              } else {
                                setCaptchaResult(
                                  "Пароль не содержит обязательные символы"
                                );
                              }
                            } else {
                              setCaptchaResult(
                                "Длина пароля меньше 8 символов"
                              );
                            }
                          } else {
                            setCaptchaResult("Пароли не совпадают");
                          }
                        } else {
                          setCaptchaResult("Не все поля заполнены");
                        }
                      } else {
                        setCaptchaResult("Неверные символы");
                      }
                      setRebootCaptcha(true);
                    }}
                    className="change_password_no_auth"
                  >
                    Восстановить
                  </div>
                </div>
              )}
              {passwordValue &&
              passwordValue.status &&
              passwordValue.status === "n" ? (
                <div>{passwordValue.status_text}</div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
