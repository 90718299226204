import FunctionChange from "components/FunctionChange";
import { useState, useEffect } from "react";
import getData from "components/unit/servicdesk/getdata/GetData";
import { FiTrash } from "react-icons/fi";
import { IoChevronForwardSharp } from "react-icons/io5";
import "../../css/settings.css";

function SettingServiceDesk() {
  const [typeTicket, setTypeTicket] = useState([]);
  const [nameStatus, setNameStatus] = useState([]);

  const getTypeTicket = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "tickets_type",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingTypeTicket =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setTypeTicket(settingTypeTicket);
  };

  const getNameStatus = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "statuses",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingNameStatus =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setNameStatus(settingNameStatus);
  };

  useEffect(() => {
    getTypeTicket();
    getNameStatus();
  }, []);

  function getValueTypeTickets(fieldType, value, id_item) {
    getTypeTicket(id_item, "", fieldType, value);
  }

  function getValueStatus(fieldType, value, id_item) {
    getNameStatus(id_item, "", fieldType, value);
  }

  return (
    <>
      <div className="col-8 mb-4">
        <div className="border_background p-4">
          <div className="border_bg_b_c_three a-text-2 pb-2">
            Настройка статусов задач
          </div>

          <div className="pb-4 pt-2 a-flex-wrap">
            {nameStatus
              ? nameStatus.map((nameStatusvalue, index) =>
                  nameStatusvalue && nameStatusvalue.name ? (
                    <div key={index} className="a-flex">
                      <FunctionChange
                        getValue={getValueStatus}
                        propsValue={nameStatusvalue.name.value}
                        fieldType={nameStatusvalue.name.field}
                        propsState={nameStatusvalue.new}
                        propsIditem={nameStatusvalue.id.value}
                        access={nameStatusvalue.name.access}
                      />
                      {nameStatusvalue.name.access &&
                      nameStatusvalue.name.access === "change" ? (
                        <div
                          className="block_delete_fitrash_no_border"
                          onClick={() =>
                            getNameStatus(nameStatusvalue.id.value, "delete")
                          }
                        >
                          <FiTrash />
                        </div>
                      ) : (
                        false
                      )}
                      <div className="settings-iochevronforwardsharp">
                        <IoChevronForwardSharp />
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )
              : false}
          </div>
          <div className="btn_one" onClick={() => getNameStatus("", "add")}>
            Добавить
          </div>
        </div>
      </div>
      <div className="col-4 mb-4">
        <div className="border_background p-4">
          <div className="border_bg_b_c_three a-text-2 pb-2">Типы задач</div>

          <div className=" ">
            <div className="pb-2 pt-2">
              {typeTicket
                ? typeTicket.map((typeTicketvalue, index) =>
                    typeTicketvalue ? (
                      <div
                        key={index}
                        className="settings-bottom-right-items a-flex"
                      >
                        <FunctionChange
                          getValue={getValueTypeTickets}
                          propsValue={typeTicketvalue.name.value}
                          fieldType={typeTicketvalue.name.field}
                          propsState={typeTicketvalue.new}
                          propsIditem={typeTicketvalue.id.value}
                        />

                        <div
                          className="block_delete_fitrash_no_border"
                          onClick={() =>
                            getTypeTicket(typeTicketvalue.id.value, "delete")
                          }
                        >
                          <FiTrash />
                        </div>
                      </div>
                    ) : (
                      false
                    )
                  )
                : false}
            </div>
            <div onClick={() => getTypeTicket("", "add")} className="btn_one">
              Добавить
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingServiceDesk;
