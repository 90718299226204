import {
  Subject,
  ID,
  Priority,
  StatusTask,
  DateControl,
  Project,
  UsersCoExecutor,
  UsersExecutor,
  UsersObserver,
  UsersInitiator,
} from "../const/ConstTicket";
import { Link } from "react-router-dom";
import getData from "../getdata/GetData";
import React, { useState, useEffect } from "react";
import FilterPanel from "./FilterPanel";
import $ from "jquery";
import moment from "moment";
import { AiFillCheckSquare, AiOutlineCloseSquare } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";
import { FcFlashOn } from "react-icons/fc";

const TableTickets = ({ client }) => {
  const [tickets, setTickets] = useState([]); // array with tickets
  const [paginationBlock, setPaginationBlock] = useState([]); // array pagination
  const [sortColumn, setSortColumn] = useState([]);
  const [ticketFieldChange, setTicketFieldChange] = useState([]);
  const [openNotes, setOpenNotes] = useState(false);

  // Для Колоранти
  const currentUrl = window.location.href; // Для Колоранти
  const containsLocation = currentUrl.includes("coloranti"); // Для Колоранти

  useEffect(() => {
    (async () => {
      const request_parameters = {
        module: "sd",
        block: "tickets_list",
        type: "get",
      };
      const result = await getData(request_parameters);

      const arrTickets =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];
      console.log(result);
      setTickets(arrTickets);

      const arrPagination =
        result &&
        result.request_attribute &&
        result.request_attribute.pagination
          ? result.request_attribute.pagination
          : [];
      setPaginationBlock(arrPagination);

      const arrSort =
        result && result.request_attribute && result.request_attribute.sort
          ? result.request_attribute.sort
          : [];
      setSortColumn(arrSort);
    })();

    document.addEventListener("click", handleOutsideClick);
  }, []);

  const changeListState = async (typeState, field_type, field_value) => {
    const request_parameters = {
      module: "sd",
      block: "tickets_list",
      type: "get",
    };
    const request_attribute = {
      typeChangeState: typeState,
      field: field_type,
      value: field_value,
    };
    const result = await getData(request_parameters, request_attribute);

    const arrPagination =
      result && result.request_attribute && result.request_attribute.pagination
        ? result.request_attribute.pagination
        : [];
    setPaginationBlock(arrPagination);

    const arrSort =
      result && result.request_attribute && result.request_attribute.sort
        ? result.request_attribute.sort
        : [];
    setSortColumn(arrSort);

    const arrTickets =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setTickets(arrTickets);
  };

  function openFieldChange(valueTicketID, typeFieldChange) {
    const arrParam = {
      paramValueTicketID: valueTicketID,
      paramTypeFieldChange: typeFieldChange,
    };
    setTicketFieldChange(arrParam);
  }

  const handleOutsideClick = (event) => {
    if (
      !event.target.closest(".burgerButton") &&
      !event.target.closest(".date-control")
    ) {
      setTicketFieldChange([]);
    }

    if (!event.target.closest(".task-notes-block")) {
      setOpenNotes(false);
    }
  };

  async function fieldSave(fieldValue) {
    const request_parameters = {
      module: "sd",
      block: "tickets_list",
      type: "get",
    };

    const request_attribute = [];

    const request_data = {
      items: [
        {
          id_ticket: ticketFieldChange.paramValueTicketID,
          typeField: ticketFieldChange.paramTypeFieldChange,
          valueField: fieldValue,
        },
      ],
    };

    if (
      ticketFieldChange.paramTypeFieldChange &&
      ticketFieldChange.paramValueTicketID > 0
    ) {
      const result = await getData(
        request_parameters,
        request_attribute,
        request_data
      );
      console.log(request_data);
      console.log(result);

      const arrTickets =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [1];
      setTickets(arrTickets);
    }

    setTicketFieldChange([]);
  }

  const renderTableData = () => {
    return tickets.map(
      (ticket, index_ticket) =>
        ticket && ticket.id && ticket.id.value ? (
          <div key={index_ticket} className="tabletickets_result_list">
            <div className="tabletickets-result">
              <div className="taskList-item thead tabletickets-grid-template">
                <div
                  className={`burger ${
                    ticketFieldChange.paramValueTicketID === ticket.id.value &&
                    ticketFieldChange.paramTypeFieldChange === "burgermenu"
                      ? "active"
                      : ""
                  }`}
                >
                  <div
                    className="burgerButton"
                    onClick={() =>
                      openFieldChange(ticket.id.value, "burgermenu")
                    }
                  >
                    <div className="middle"></div>
                  </div>
                  <div className="burgermenu border_background">
                    {ticket.menu.map((menu, index) => (
                      <div
                        key={index}
                        className="burger-menu-item a-pointer"
                        onClick={() => fieldSave(menu.id)}
                      >
                        {menu.value}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="id-box">
                  <div className="a-flex-center">
                    {/* Для колоранти */}
                    {containsLocation ? (
                      false
                    ) : (
                      <Link to={`/task/${ticket.id.value}/`}>
                        {ticket.id.value}
                      </Link>
                    )}
                  </div>
                  {ticket &&
                  ticket.priority &&
                  ticket.priority.id &&
                  ticket.priority.id === "3" ? (
                    <div>
                      <FcFlashOn />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div
                  className={`subject${
                    ticket.notification === "y" ? " new_comment" : ""
                  }`}
                >
                  {(ticket.notes &&
                    (ticket.notes.private || ticket.notes.public)) ||
                  ticket.comments ? (
                    <div className="a-flex-center task-notes-block">
                      {openNotes && openNotes === index_ticket + 1 ? (
                        <div
                          className="a-pointer task-notes-button active"
                          onClick={() => setOpenNotes(false)}
                        >
                          <CgNotes />
                        </div>
                      ) : (
                        <div
                          className="a-pointer task-notes-button "
                          onClick={() => setOpenNotes(index_ticket + 1)}
                        >
                          <CgNotes />
                        </div>
                      )}

                      <div
                        className={`quick-view-public-notes ${
                          openNotes && openNotes === index_ticket + 1
                            ? "active"
                            : ""
                        }`}
                      >
                        {ticket.notes &&
                        (ticket.notes.public || ticket.notes.private) ? (
                          <div className=" task-notes-blok">
                            <div className="task-notes-title">Заметки</div>
                            <div className="task-notes-items">
                              {ticket.notes.private &&
                              ticket.notes.private.value ? (
                                <div className="task-notes-item">
                                  <div className="task-notes-item-title">
                                    Личная заметка
                                  </div>
                                  <div className="task-notes-item-value">
                                    {ticket.notes.private.value}
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                              {ticket.notes.public &&
                              ticket.notes.public.value ? (
                                <div className="task-notes-item">
                                  <div className="task-notes-item-title">
                                    Общая заметка: {ticket.notes.public.user}{" "}
                                    <span>{ticket.notes.public.date}</span>
                                  </div>
                                  <div className="task-notes-item-value">
                                    {ticket.notes.public.value}
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </div>
                        ) : (
                          false
                        )}

                        {ticket.comments && ticket.comments.length > 0 ? (
                          <div
                            className={`task-notes-blok ${
                              ticket.notes &&
                              (ticket.notes.public || ticket.notes.private)
                                ? "task-notes-blok-comment"
                                : ""
                            }`}
                          >
                            <div className="task-notes-title">Комментарии</div>
                            <div className="task-notes-items">
                              {ticket.comments.map((comment, index_comment) => (
                                <div
                                  key={index_comment}
                                  className="task-notes-item"
                                >
                                  <div className="task-notes-item-title">
                                    {comment.user} <span>{comment.date}</span>
                                  </div>
                                  <div className="task-notes-item-value">
                                    {comment.value}{" "}
                                    {comment.files !== "" ? "(файл)" : false}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  <Link
                    to={`/task/${ticket.id.value}/`}
                    className="subject_link"
                  >
                    {ticket.subject.value}
                  </Link>
                </div>

                <div className="date-control">
                  {ticket.date_control.value ? (
                    ticketFieldChange.paramValueTicketID === ticket.id.value &&
                    ticketFieldChange.paramTypeFieldChange ===
                      "date_control" ? (
                      <div className="date-control-input-block">
                        <div className="date-control-input">
                          <input
                            id="dateInputTicketList"
                            className=""
                            type="datetime-local"
                            defaultValue={`${moment(
                              ticket.date_control.value,
                              "YYYY.MM.DD HH:mm:ss"
                            ).format("YYYY-MM-DDTHH:mm")}`}
                          ></input>
                        </div>

                        <div className="selector-date-save-cancel-tableticket">
                          <div
                            className="selector-date-save-tableticket"
                            onClick={(e) => {
                              var inputVal = $("#dateInputTicketList").val();
                              fieldSave(inputVal);
                            }}
                          >
                            <AiFillCheckSquare />
                          </div>
                          <div
                            className="selector-date-cancel-tableticket"
                            onClick={() => setTicketFieldChange([])}
                          >
                            <AiOutlineCloseSquare />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        data-period={ticket.date_control.period}
                        data-value={ticket.date_control.value}
                        className={`data-control-area-block ${
                          ticket.date_control.period === 1 ? "orange" : ""
                        } ${ticket.date_control.period === 2 ? "red" : ""}`}
                      >
                        <div
                          className="example-custom-input"
                          onClick={() =>
                            openFieldChange(ticket.id.value, "date_control")
                          }
                        >
                          {ticket.date_control.period === 0
                            ? moment(
                                ticket.date_control.value,
                                "YYYY.MM.DD HH:mm"
                              ).format("DD.MM.YYYY HH:mm")
                            : ticket.date_control.label}
                        </div>
                      </div>
                    )
                  ) : (
                    "не определена"
                  )}
                </div>

                <div className={"status-task status_color_" + ticket.status.id}>
                  <div className="status_color">{ticket.status.value}</div>
                </div>
                <div className="users-executor">
                  {Array.isArray(ticket.users_executor.value) ? (
                    <div>
                      {ticket.users_executor.value.map(
                        (users_executor_value, index2) => (
                          <div key={index2}>{users_executor_value}</div>
                        )
                      )}
                    </div>
                  ) : (
                    <div>{ticket.users_executor.value}</div>
                  )}
                </div>
                {/* Для колоранти */}
                {containsLocation ? (
                  <div className="users-initiator">
                    {ticket.users_initiator.value}
                  </div>
                ) : (
                  <>
                    <div className="project">{ticket.project.value}</div>
                    <div className="priority">{ticket.priority.value}</div>

                    <div className="users-initiator">
                      {ticket.users_initiator.value}
                    </div>

                    <div className="users-observer">
                      {ticket.users_observer.value}
                    </div>
                    <div className="users-co-executor">
                      {ticket.users_co_executor.value}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          false
        )

      // end map
    );
  };

  function paginationButton() {
    const n = paginationBlock.count_pages;
    let count_items_on_page = paginationBlock.count_items_on_page;
    if (!count_items_on_page > 0) {
      count_items_on_page = 10;
    }
    let count_items_all = paginationBlock.count_items;
    if (!count_items_all > 0) {
      count_items_all = 0;
    }
    const elements = [];
    for (let i = 1; i <= n; i++) {
      let element;
      if (i === paginationBlock.current_page) {
        element = (
          <div className="nuber-p" key={i}>
            <b> {i}</b>
          </div>
        );
      } else {
        element = (
          <div
            className="pagination-num"
            key={i}
            onClick={() => changeListState("pagination", "page", i)}
          >
            {" "}
            {i}
          </div>
        );
      }
      elements.push(element);
    }
    function paginationSelectedOpen() {
      $(".cont-num-ticket-list").addClass("show");
    }
    return (
      <div className="pagination-pagination-num">
        <div className="pagination-count-info">
          Всего задач: {count_items_all}
        </div>
        {elements && elements.length > 1 ? (
          <div className="pagination-button">{elements}</div>
        ) : (
          false
        )}

        <div className="selecting-number-ticket">
          <div className="title_number_ticket_block">
            <div className="title_number_ticket">
              Показывать задач на странице:
            </div>

            <div className="cont-num-ticket-list-block ">
              <div
                className="cont-num-ticket-list show active"
                onClick={() => paginationSelectedOpen()}
              >
                {count_items_on_page}
              </div>

              {count_items_on_page !== 10 ? (
                <div
                  className={`cont-num-ticket-list ${
                    count_items_on_page === 10 ? "active" : ""
                  }`}
                  onClick={() => {
                    changeListState("pagination", "count", 10);
                    $(".cont-num-ticket-list").removeClass("show");
                  }}
                >
                  10
                </div>
              ) : (
                false
              )}

              {count_items_on_page !== 20 ? (
                <div
                  className={`cont-num-ticket-list ${
                    count_items_on_page === 20 ? "active" : ""
                  }`}
                  onClick={() => {
                    changeListState("pagination", "count", 20);
                    $(".cont-num-ticket-list").removeClass("show");
                  }}
                >
                  20
                </div>
              ) : (
                false
              )}

              {count_items_on_page !== 30 ? (
                <div
                  className={`cont-num-ticket-list ${
                    count_items_on_page === 30 ? "active" : ""
                  }`}
                  onClick={() => {
                    changeListState("pagination", "count", 30);
                    $(".cont-num-ticket-list").removeClass("show");
                  }}
                >
                  30
                </div>
              ) : (
                false
              )}

              {count_items_on_page !== 50 ? (
                <div
                  className={`cont-num-ticket-list ${
                    count_items_on_page === 50 ? "active" : ""
                  }`}
                  onClick={() => {
                    changeListState("pagination", "count", 50);
                    $(".cont-num-ticket-list").removeClass("show");
                  }}
                >
                  50
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function column_title() {
    const typeSort = sortColumn.field;
    const directionSort = sortColumn.value;

    let classActiveSort;
    if (directionSort == "ASC") {
      classActiveSort = "active_top";
    } else {
      classActiveSort = "active_bottom";
    }

    return (
      <div className="taskList-item-tittle tabletickets-grid-template">
        <div className="empty-block"></div>

        <div
          className={`id-box ${typeSort === "id" ? classActiveSort : ""}`}
          onClick={() => changeListState("sort", "id", "")}
        >
          {/* Для Колоранти */}
          {containsLocation ? (
            false
          ) : (
            <div className="sorting-arrow cursor_pointer">{ID}</div>
          )}
        </div>

        <div className="subject">
          <div className="sorting-arrow_none">{Subject}</div>
        </div>

        <div
          className={`cursor_pointer date-control ${
            typeSort === "date_control" ? classActiveSort : ""
          }`}
          onClick={() => changeListState("sort", "date_control", "")}
        >
          <div className="sorting-arrow">{DateControl}</div>
        </div>

        <div
          className={`cursor_pointer status-task  ${
            typeSort === "status" ? classActiveSort : ""
          }`}
          onClick={() => changeListState("sort", "status", "")}
        >
          <div className="sorting-arrow">{StatusTask}</div>
        </div>

        <div className="users-executor">
          <div className="sorting-arrow_none">{UsersExecutor}</div>
        </div>

        {/* Для Колоранти */}
        {containsLocation ? (
          <div className="users-initiator">
            <div className="sorting-arrow_none">{UsersInitiator}</div>
          </div>
        ) : (
          <>
            <div className="project">
              <div className="sorting-arrow_none">{Project}</div>
            </div>

            <div
              className={`cursor_pointer priority ${
                typeSort === "priority" ? classActiveSort : ""
              }`}
              onClick={() => changeListState("sort", "priority")}
            >
              <div className="sorting-arrow">{Priority}</div>
            </div>

            <div className="users-initiator">
              <div className="sorting-arrow_none">{UsersInitiator}</div>
            </div>
            <div className="users-observer">
              <div className="sorting-arrow_none">{UsersObserver}</div>
            </div>
            <div className="users-co-executor">
              <div className="sorting-arrow_none">{UsersCoExecutor}</div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="tabletickets-title">
      <FilterPanel changeListState={changeListState} />

      <div className="tasklist__pagination">
        <div className="taskList_item_result border_background">
          <div className="taskList_item__scrolling_block">
            <div>{column_title()}</div>
            <div className="result-list-tasks">
              <div className="result-list-scrolling-block">
                {renderTableData()}
              </div>
            </div>
          </div>
        </div>
        <div className="pagination border_background">
          <div className="pagination-block">{paginationButton()}</div>
        </div>
      </div>
    </div>
  );
};

export default TableTickets;
