import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getData from "../getdata/GetData";
import FunctionChange from "../../../FunctionChange";
import { arrListСounterpartieContacts } from "../const/ConstTicket";
import { FiTrash } from "react-icons/fi";
import ChangePasswordAuth from "../settings/ChangePasswordAuth";

function СounterpartieContacts() {
  const { id } = useParams();
  const [arrСounterpartieContacts, setArrСounterpartieContacts] = useState([]);

  useEffect(() => {
    getСounterpartieContacts();
  }, []);

  const getСounterpartieContacts = async (
    id_item,
    action,
    edit_field,
    value
  ) => {
    const request_parameters = {
      module: "settings",
      block: "counterpartie_branch_contact",
    };
    const request_attribute = {
      type_item: "contact",
      id_counterpartie: id,
      id_item: id_item,
      action: action,
    };

    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrListСounterpartieContacts =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setArrСounterpartieContacts(arrListСounterpartieContacts);
  };

  function getValue(fieldType, value, id_item) {
    getСounterpartieContacts(id_item, "", fieldType, value);
  }

  return (
    <div className="counterpartie_contacts">
      <div className="border_background col-12 p-4">
        <div className="border_bg_b_c_three a-flex pb-2">
          <div className="a-text-2 pb-2">Контакты</div>
        </div>

        <div className="counterpartie_contacts_items">
          {arrСounterpartieContacts
            ? arrСounterpartieContacts.map(
                (counterpartiecontactsvalue, index) =>
                  counterpartiecontactsvalue ? (
                    <div
                      className="pt-4 pb-4 border_bg_b_c_three a-relative"
                      key={index}
                    >
                      <div className="pb-2 a-text-3">
                        {counterpartiecontactsvalue.name.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={counterpartiecontactsvalue.name.value}
                            fieldType={counterpartiecontactsvalue.name.field}
                            propsState={counterpartiecontactsvalue.new}
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}{" "}
                      </div>

                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.branch.title}
                        </div>
                        {counterpartiecontactsvalue.branch.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={counterpartiecontactsvalue.branch.value}
                            fieldType={counterpartiecontactsvalue.branch.field}
                            propsInput="select"
                            propsParamData={{
                              module: "settings",
                              block: "counterpartie_branch_contact",
                              for: "branch",
                              group_id: id,
                            }}
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.post.title}
                        </div>
                        {counterpartiecontactsvalue.post.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={counterpartiecontactsvalue.post.value}
                            fieldType={counterpartiecontactsvalue.post.field}
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.phone.title}
                        </div>
                        {counterpartiecontactsvalue.phone.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={counterpartiecontactsvalue.phone.value}
                            fieldType={counterpartiecontactsvalue.phone.field}
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.mail.title}
                        </div>
                        {counterpartiecontactsvalue.mail.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={counterpartiecontactsvalue.mail.value}
                            fieldType={counterpartiecontactsvalue.mail.field}
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.telegram.title}
                        </div>
                        {counterpartiecontactsvalue.telegram.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              counterpartiecontactsvalue.telegram.value
                            }
                            fieldType={
                              counterpartiecontactsvalue.telegram.field
                            }
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      <div className="pb-2 counterpartie_contacts_item_grid">
                        <div className="">
                          {arrListСounterpartieContacts.whatsapp.title}
                        </div>
                        {counterpartiecontactsvalue.whatsapp.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              counterpartiecontactsvalue.whatsapp.value
                            }
                            fieldType={
                              counterpartiecontactsvalue.whatsapp.field
                            }
                            propsIditem={counterpartiecontactsvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      <div>
                        {counterpartiecontactsvalue.account &&
                        counterpartiecontactsvalue.account.value &&
                        counterpartiecontactsvalue.account.value > 0 ? (
                          <div className="password-btn-block">
                            <div className="">
                              <ChangePasswordAuth
                                id_item={
                                  counterpartiecontactsvalue.account.value
                                }
                                type_password={
                                  counterpartiecontactsvalue.account.pass &&
                                  counterpartiecontactsvalue.account.pass ===
                                    "n"
                                    ? "props_new"
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              {counterpartiecontactsvalue.telegram_bot &&
                              counterpartiecontactsvalue.telegram_bot.code &&
                              counterpartiecontactsvalue.telegram_bot.code >
                                0 ? (
                                <div>
                                  Для подключения Телеграм бота{" "}
                                  <a
                                    href="https://t.me/aritin_bot"
                                    className="a-text--c-three"
                                    target="_blank"
                                  >
                                    @aritin_bot
                                  </a>
                                  , используйте код:{" "}
                                  <b>
                                    {
                                      counterpartiecontactsvalue.telegram_bot
                                        .code
                                    }
                                  </b>
                                </div>
                              ) : (
                                <div
                                  className="a-btn-block-bottom-left"
                                  onClick={() =>
                                    getСounterpartieContacts(
                                      counterpartiecontactsvalue.account.value,
                                      "connect_telegram"
                                    )
                                  }
                                >
                                  Телеграм
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="a-btn-block-bottom-left"
                            onClick={() => {
                              if (window.confirm("Создать личный кабинет?")) {
                                getСounterpartieContacts(
                                  counterpartiecontactsvalue.id.value,
                                  "new_account"
                                );
                              }
                            }}
                          >
                            Создать личный кабинет
                          </div>
                        )}
                      </div>

                      <div className="pb-4">
                        <div
                          className="fitrash-bottom-right"
                          onClick={() => {
                            if (window.confirm("Удалить контакт?")) {
                              getСounterpartieContacts(
                                counterpartiecontactsvalue.id.value,
                                "delete"
                              );
                            }
                          }}
                        >
                          <FiTrash />
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )
              )
            : false}
        </div>
        <div className="pt-4">
          <div
            className="a-btn-block-bottom-left"
            onClick={() => getСounterpartieContacts("", "add")}
          >
            Добавить контакт
          </div>
        </div>
      </div>
    </div>
  );
}

export default СounterpartieContacts;
