import { useState, useEffect } from "react";
import SettingServiceDesk from "./SettingServiceDesk";
import SettingCompany from "./SettingCompany";
import getData from "components/unit/servicdesk/getdata/GetData";
import PlugAccess from "../PlugAccess";
import "../../css/settings.css";

function MainSettings() {
  const [responseCode, setResponseCode] = useState(null);

  const getCompanyInformation = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "main_settings",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingResponseCode =
      result && result.request_attribute && result.request_attribute.status_code
        ? result.request_attribute.status_code
        : null;

    setResponseCode(settingResponseCode);
  };
  useEffect(() => {
    getCompanyInformation();
  }, []);

  if (responseCode && responseCode === "403") {
    return <PlugAccess />;
  } else if (responseCode && responseCode === "200") {
    return (
      <>
        <div className="setting-block">
          <div className="container">
            <div className="row">
              <SettingCompany />
              <SettingServiceDesk />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainSettings;
