import axios from "axios";

async function getData(
  request_parameters,
  request_attribute,
  request_data,
  file
) {
  let apiUrl =
    "https://aritin.ru/api/b61d80cf423f1b27d2ed07b4877ef60c6944015796ebbb1ad6/faskdjfldsuahgfpasudhgy95329p-8hposfhdg.php";

  const request_parameters_json = JSON.stringify(request_parameters);
  const request_attribute_json = JSON.stringify(request_attribute);
  const request_data_json = JSON.stringify(request_data);

  const formData = new FormData();

  formData.append("request_parametrs", request_parameters_json);
  formData.append("request_attribute", request_attribute_json);
  formData.append("request_data", request_data_json);

  if (file) {
    let filesArray = Array.from(file);
    filesArray.forEach((file1, index) => {
      formData.append("files[]", file1); // 'files[]' название поля, которое будет содержать файлы
    });
  }

  return axios
    .post(apiUrl, formData, {
      withCredentials: true, // Включение креденшиалов (cookie)
      credentials: "include", // Разрешение отправки cookie
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      // Обработка полученных данных

      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export default getData;
