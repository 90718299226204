import React, { useState, useEffect, useRef } from "react";
import getData from "../getdata/GetData";
import { useParams } from "react-router-dom";
import TicketFastMain from "./TicketFastMain";
import "../../../../css/ticketfast.css";
import TicketsFastComments from "./TicketFastComments";
import NotFound from "components/NotFound";
import PlugAccess from "components/PlugAccess";
import NotPaid from "components/NotPaid";
import Unauthorized from "components/Unauthorized";

function TicketsFast() {
  let { id } = useParams();
  const [idTicketsFast, setidTicketsFast] = useState(false);
  const [ticketChangeBox, setTicketChangeBox] = useState(false);
  const [ticketStatusCode, setTicketStatusCode] = useState("");
  const idRef = useRef(id);

  useEffect(() => {
    if (idRef !== id && id === "new") {
      setidTicketsFast(id);
    }
  }, [id]); //понимаем что id изменился

  useEffect(() => {
    if (idTicketsFast === false) {
      setidTicketsFast(id);
    }

    if (idTicketsFast === "new") {
      getTicketsFastNew();
    }
  }, [idTicketsFast]);

  async function getTicketsFastNew() {
    if (idTicketsFast === "new") {
      const request_parameters = { module: "sd", block: "fast_tickets_main" };
      const request_attribute = { id_ticket_fast: id };
      const result = await getData(request_parameters, request_attribute);
      const idNewTicketFast =
        result && result.request_data && result.request_data.id
          ? result.request_data.id
          : [];
      if (idTicketsFast === "new" && idNewTicketFast > 0) {
        window.location.replace("/ticket/" + idNewTicketFast + "/");
      }
    }
  }

  if (idTicketsFast > 0) {
    return (
      <div className="container">
        {ticketStatusCode === "404" ? (
          <NotFound />
        ) : ticketStatusCode === "403" ? (
          <PlugAccess />
        ) : ticketStatusCode === "402" ? (
          <NotPaid />
        ) : ticketStatusCode === "401" ? (
          <Unauthorized />
        ) : (
          <>
            <div>
              <TicketFastMain
                id_ticket_fast={id}
                setTicketStatusCode={setTicketStatusCode}
              />
            </div>
            <div>
              <TicketsFastComments
                id_ticket_fast={id}
                ticket_change_box={ticketChangeBox}
                set_ticket_change_box={setTicketChangeBox}
              />
            </div>
          </>
        )}
      </div>
    );
  } else {
    return <div>Заявка создается</div>;
  }
}

export default TicketsFast;
