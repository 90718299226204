import React, { useState, useEffect, useRef } from "react";
import getData from "../getdata/GetData";
import { useParams } from "react-router-dom";
import CheckList from "../tickets/CheckList";
import TicketControlPanel from "../tickets/TicketControlPanel";
import TicketMain from "../tickets/TicketMain";
import NotFound from "components/NotFound";
import PlugAccess from "components/PlugAccess";
import NotPaid from "components/NotPaid";
import Unauthorized from "components/Unauthorized";

function TemplateTicket() {
  let { id } = useParams();
  const [idTicket, setIdTicket] = useState(id);
  const [changeField, setChangeField] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [ticketStatusCode, setTicketStatusCode] = useState("");
  const changeFieldRef = useRef(changeField);
  const idRef = useRef(id);

  useEffect(() => {
    if (idRef !== id && id === "new") {
      setIdTicket(id);
    }
  }, [id]);

  useEffect(() => {
    changeFieldRef.current = changeField;
  }, [changeField]);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  //добавить проверку на существования id задачи

  useEffect(() => {
    if (idTicket === false) {
      setIdTicket(id);
    }

    if (idTicket === "new") {
      getTicketNew();
    }
  }, [idTicket]);

  async function getTicketNew() {
    if (idTicket === "new") {
      const request_parameters = { module: "sd", block: "ticket_main" };
      const request_attribute = { id_ticket: id };
      const result = await getData(request_parameters, request_attribute);
      const idNewTicket =
        result && result.request_data && result.request_data.id
          ? result.request_data.id
          : [];
      if (idTicket === "new" && idNewTicket > 0) {
        window.location.replace("/tickets/" + idNewTicket + "/");
      }
    }
  }

  function handlePaste(pasteEvent) {
    const item = pasteEvent.clipboardData.items[0];
    if (item.type.indexOf("image") === 0) {
      var blob = item.getAsFile();

      setSelectedFile((prevSelectedFile) => [...prevSelectedFile, blob]);
    }
  }

  function addOnChange(changeFile) {
    setSelectedFile((prevSelectedFile) => [
      ...prevSelectedFile,
      changeFile.target.files[0],
    ]);
  }

  const deleteFileFromState = (index) => {
    const newArray = [...selectedFile]; // Создаем копию массива
    newArray.splice(index, 1); // Удаляем элемент по индексу
    setSelectedFile(newArray); // Обновляем состояние с новым массивом
  };

  if (idTicket > 0) {
    return (
      <div className="landing-ticket">
        {ticketStatusCode === "404" ? (
          <NotFound />
        ) : ticketStatusCode === "403" ? (
          <PlugAccess />
        ) : ticketStatusCode === "402" ? (
          <NotPaid />
        ) : ticketStatusCode === "401" ? (
          <Unauthorized />
        ) : (
          <div>
            <div className="a-text-1 p-2">Шаблон</div>
            <div className="element-loading"></div>
            <div className="control-panel-ticket-body">
              <div className="ticket-block-body">
                <div className="ticket-block-body-tabs-content">
                  <div className="content_ticket_block_body border_background">
                    <TicketMain
                      idTicket={idTicket}
                      changeField={changeField}
                      selectedFile={selectedFile}
                      setChangeField={setChangeField}
                      setSelectedFile={setSelectedFile}
                      deleteFileFromState={deleteFileFromState}
                      addOnChange={addOnChange}
                      typeItem={"template"}
                      setTicketStatusCode={setTicketStatusCode}
                    />
                    <CheckList typeItem={"template"} />
                  </div>
                </div>
              </div>

              <TicketControlPanel
                idTicket={idTicket}
                changeField={changeField}
                setChangeField={setChangeField}
                changeFieldRef={changeFieldRef}
                typeItem={"template"}
              />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <div>Задача создается</div>;
  }
}

export default TemplateTicket;
