import { useEffect, useState } from "react";
import "../css/message.css";
import {
  IoCloseSharp,
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
} from "react-icons/io5";

function Message({ text, status, operation }) {
  const [textState, setTextState] = useState("");
  const [statusState, setStatusState] = useState("");
  const [messageClose, setMessageClose] = useState(false);

  useEffect(() => {
    setTextState(text);
    setStatusState(status);
    setMessageClose(true);
  }, [text, status, operation]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".message-text-state")) {
      setTextState("");
      setStatusState("");
      setMessageClose(false);
    }
  };

  if (
    messageClose &&
    textState &&
    textState.length > 0 &&
    textState !== "" &&
    statusState &&
    statusState.length > 0 &&
    statusState !== ""
  ) {
    return (
      <>
        <div className="message-modal-block">
          <div className="message">
            <div className="message-modal-close">
              <IoCloseSharp />
            </div>
            <div className="message-text-state-btn">
              <div className="message-text-state">
                <div
                  className={"message-icon-state message-status-" + statusState}
                >
                  {statusState === "fail" ? (
                    <IoCloseCircleSharp />
                  ) : (
                    <IoCheckmarkCircleSharp />
                  )}
                </div>
                <div>{textState}</div>
              </div>
              <div className="a-btn-block">ОК</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Message;
